import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { PropertyFiltersService } from '../../../../shared/services/property-filters.service';
import { MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatDatepickerToggle, MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { ControlErrorComponent } from '../../../../shared/components/form-helpers/control-error/control-error.component';
import { IncDecComponent } from '../../../../shared/components/inc-dec/inc-dec.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'vh-property-filters-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogClose,
    MatDialogContent,
    MatIconButton,
    MatButton,
    MatDateRangePicker,
    MatDateRangeInput,
    MatDatepickerToggle,
    MatStartDate,
    MatEndDate,
    NgIf,
    ControlErrorComponent,
    IncDecComponent,
    AsyncPipe,
    MatIcon,
    MatFormField,
    MatLabel,
    MatSuffix,
    MatInput,
    MatError,
    ReactiveFormsModule
  ],
  templateUrl: './property-filters-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyFiltersDialogComponent implements OnInit, OnDestroy {
  public minDate = dayjs();

  public get priceRangeGroup(): FormGroup { return this.formGroup.get('priceRange') as FormGroup; }
  public get dateRangeGroup(): FormGroup { return this.formGroup.get('dateRange') as FormGroup; }

  public filtersChanged = new BehaviorSubject<boolean>(false);

  private _unsubscribe = new Subject<void>();

  public formGroup: FormGroup = this.formBuilder.group({
    dateRange: this.formBuilder.group({
      start: [null],
      end: [null]
    }),
    priceRange: this.formBuilder.group({
      min: [null, [Validators.min(0)]],
      max: [null, [Validators.min(0)]]
    }),
    guests: [1],
    bedrooms: [0],
    bathrooms: [0]
  });

  constructor(
    private formBuilder: FormBuilder,
    private propertyFilters: PropertyFiltersService,
    private dialogRef: MatDialogRef<PropertyFiltersDialogComponent>,
    private snackBar: SnackBarService) { }

  public ngOnInit(): void {
    this.propertyFilters.currentFilters$.pipe(take(1)).subscribe(currentFilters => {
      this.formGroup.patchValue(currentFilters as any);
    });

    this.formGroup.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(value => {
      this.filtersChanged.next(!PropertyFiltersService.areDefaultFilters(value));
    });

    this.filtersChanged.next(!PropertyFiltersService.areDefaultFilters(this.formGroup.value));
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public checkMinMax() {
    const minValue = this.priceRangeGroup.get('min')?.value;
    const maxValue = this.priceRangeGroup.get('max')?.value;

    if ((minValue || minValue === 0) && (maxValue || maxValue === 0)) {
      if (minValue > maxValue) {
        this.priceRangeGroup.get('min')?.setValue(null);

        this.snackBar.showError('Minimum price must not exceed maximum price');

        return;
      }
    }
  }

  public checkDates() {
    const start = this.dateRangeGroup?.get('start')?.value;
    const end = this.dateRangeGroup?.get('end')?.value;

    if (start && end) {
      if (end.isSameOrBefore(start, 'day')) {
        setTimeout(() => {
          this.dateRangeGroup.get('start')?.setValue(null, { emitEvent: false });
          this.dateRangeGroup.get('end')?.setValue(null, { emitEvent: false });

          this.snackBar.showError('At least one night must be selected');

          this.dateRangeGroup.updateValueAndValidity();
        });

        return;
      }
    }
  }

  public resetAll() {
    this.formGroup.setValue(PropertyFiltersService.getEmptyFilters(), { emitEvent: false });

    this.formGroup.updateValueAndValidity();
  }

  public submit() {
    this.propertyFilters.applyFilters(this.formGroup.value);
    this.dialogRef.close(true);
  }


}
