<div mat-dialog-title class="!flex items-center justify-between !tracking-normal !pt-4">
    <div class="grow">Filters</div>
    <button aria-label="Close" mat-icon-button mat-dialog-close>
        <mat-icon svgIcon="vhc-close"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form id="filtersFormGroup" [formGroup]="formGroup!" (ngSubmit)="submit()">
        <mat-form-field appearance="outline" class="mt-2 w-full" hideRequiredMarker>
            <mat-label>Stay Dates</mat-label>
            <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker" [min]="minDate">
                <input matStartDate formControlName="start" placeholder="Start date" readonly>
                <input matEndDate formControlName="end" placeholder="End date" readonly>
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker (closed)="checkDates()" #picker xPosition="end"
                yPosition="below"></mat-date-range-picker>
        </mat-form-field>

        <div class="grid md:grid-cols-2 gap-3 pt-3" formGroupName="priceRange">
            <mat-form-field appearance="outline" class="grow" hideRequiredMarker>
                <mat-label>Minimum Price</mat-label>
                <input type="number" matInput formControlName="min" min="0" oninput="this.value = Math.abs(this.value)"
                    (blur)="checkMinMax()">
                <mat-error *ngIf="priceRangeGroup?.get('min')?.invalid">
                    <vh-control-error label="Minimum price" controlName="min" [formGroup]="priceRangeGroup!"
                        [errorDetails]="{min: 0}">
                    </vh-control-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow" hideRequiredMarker>
                <mat-label>Maximum Price</mat-label>
                <input type="number" matInput formControlName="max" min="0" oninput="this.value = Math.abs(this.value)"
                    (blur)="checkMinMax()">
                <mat-error *ngIf="priceRangeGroup?.get('max')?.invalid">
                    <vh-control-error label="Maximum price" controlName="max" [formGroup]="priceRangeGroup!"
                        [errorDetails]="{min: 0}">
                    </vh-control-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="grid md:grid-cols-3 gap-3 pt-3" *ngIf="formGroup">
            <vh-inc-dec class="grow" text="Adults" [showMore]="true" orientation="vertical"
                [value]="formGroup.get('guests')!.value" [minValue]="1"
                (increment)="formGroup.get('guests')!.setValue($event + 1)"
                (decrement)="formGroup.get('guests')!.setValue($event - 1)"></vh-inc-dec>

            <vh-inc-dec class="grow" text="Bedrooms" [showMore]="true" orientation="vertical"
                [value]="formGroup.get('bedrooms')!.value" [minValue]="0"
                (increment)="formGroup.get('bedrooms')!.setValue($event + 1)"
                (decrement)="formGroup.get('bedrooms')!.setValue($event - 1)"></vh-inc-dec>

            <vh-inc-dec class="grow" text="Bathrooms" [showMore]="true" orientation="vertical"
                [value]="formGroup.get('bathrooms')!.value" [minValue]="0"
                (increment)="formGroup.get('bathrooms')!.setValue($event + 1)"
                (decrement)="formGroup.get('bathrooms')!.setValue($event - 1)"></vh-inc-dec>
        </div>
    </form>
</mat-dialog-content>

<div
    class="flex flex-col sm:flex-row space-y-3 pt-3 sm:space-y-0 sm:!justify-end sm:!items-center sm:!space-x-3 !px-6 !pb-6 sm:!pt-0">
    <button mat-flat-button type="button" color="primary" aria-label="Reset filters" (click)="resetAll()"
        [disabled]="(filtersChanged | async) === false" class="font-semibold tracking-normal w-full sm:w-auto">
        <span class="font-semibold tracking-normal">Clear All</span>
    </button>

    <button mat-flat-button type="submit" form="filtersFormGroup" [disabled]="formGroup.invalid" color="primary"
        aria-label="Submit form" class="font-semibold tracking-normal w-full sm:w-auto">
        <span class="font-semibold tracking-normal">Apply</span>
    </button>
</div>